import '@babel/polyfill'
import distribution from './distribution'
import * as enums from './enums'

window['Klarna'] = window['Klarna'] || {}
window['Klarna']['HPP'] = window['Klarna']['HPP'] || {}
window['Klarna']['HPP']['Distribution'] = window['Klarna']['HPP']['Distribution'] || {}

window['Klarna']['HPP']['Distribution'] = distribution

for (let enumKey in enums) {
  window['Klarna']['HPP']['Distribution'][enumKey] = {}
  for (let key in enums[enumKey]) {
    window['Klarna']['HPP']['Distribution'][enumKey][key] = enums[enumKey][key]
  }
}

if (window['klarnaHppDistributionAsyncCallback']) {
  window['klarnaHppDistributionAsyncCallback']()
}
// Legacy typo, but since one customer have been already integrated with it, we need to support it untill we shure that no one uses it
else if (window['klarnaHppDisitributionAsyncCallback']) {
  window['klarnaHppDisitributionAsyncCallback']()
}
