export enum Target {
  FULLSCREEN = 'fullscreen',
  NEW_WINDOW = 'new_window'
}

export enum Event {
  END = 'end',
  CLOSE = 'close',
  CLIENT_ERROR = 'client-error',
  KEYBOARD_INPUT_RESPONSE = 'keyboard-input-response'
}

export enum SessionStatus {
  COMPLETED = 'completed',
  FAILED = 'failed',
  CANCELLED = 'cancelled',
  DISABLED = 'disabled',
  ERROR = 'error',
  WAITING = 'waiting',
  IN_PROGRESS = 'in_progress'
}

export enum ErrorName {
  SDK_ALREADY_INITED_ERROR = 'SDKAlreadyInitedError',
  SDK_NOT_INITED_ERROR = 'SDKNotInitedError',
  INVALID_TOKEN_ERROR = 'InvalidTokenError',
  INVALID_PARAMETER_ERROR = 'InvalidParameterError',
  MODULE_DID_NOT_LOAD = 'ModuleDidNotLoad'
}
