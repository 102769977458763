import Emitter from 'events'

export default class MessageListener extends Emitter {
  onMessage: (event: MessageEvent) => void

  constructor(viewId, origin) {
    super()

    this.onMessage = function(event) {
      if (event.source !== window && event.origin === origin) {
        const { name, data, view_id: messageViewId } = JSON.parse(event.data)

        if (messageViewId.toString() === viewId.toString()) {
          this.emit(name, data)
        }
      }
    }.bind(this)

    window.addEventListener('message', this.onMessage, false)
  }

  destroy() {
    this.removeAllListeners()
    window.removeEventListener('message', this.onMessage)
  }
}
