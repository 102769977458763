export default function createNewWindow(url) {
  const iframe = document.createElement('iframe')
  iframe.id = 'klarna-hpp-payment-distribution-iframe'
  iframe.frameBorder = '0'
  // @ts-ignore
  iframe.allowTransparency = 'true'
  iframe.style.position = 'fixed'
  iframe.style.top = '0'
  iframe.style.left = '0'
  iframe.style.width = '0'
  iframe.style.height = '0'
  iframe.style.backgroundColor = 'transparent'
  iframe.src = url

  document.body.appendChild(iframe)

  return {
    show() {
      iframe.style.width = '100vw'
      iframe.style.height = '100vh'
    },
    close() {
      document.body.removeChild(iframe)
      return new Promise<void>(resolve => setTimeout(resolve, 0))
    },
    postMessage(message: string) {
      iframe.contentWindow.postMessage(message, '*')
    }
  }
}
