export default function createIframe(url) {
  const newWindow = window.open(url)

  return {
    show() {},
    close(): Promise<void> {
      newWindow.close()
      return new Promise<void>(resolve => setTimeout(resolve, 50))
    },
    postMessage(message: string) {
      newWindow.postMessage(message, '*')
    }
  }
}
