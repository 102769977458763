import Emitter from 'events'
import jwtDecode from 'jwt-decode'
import uuidV4 from 'uuid/v4'
import { Target, Event, ErrorName } from './enums'
import createTracker from './create-tracker'
import MessageListener from './message-listener'
import createIframe from './create-iframe'
import createNewWindow from './create-new-window'
import { createError } from './utils'

const avaiableTargets = Object.values(Target)
let sdkInited = false

function init() {
  if (sdkInited) {
    throw createError(
      ErrorName.SDK_ALREADY_INITED_ERROR,
      'The HPP Distribution Module SDK is already initialized.'
    )
  }

  sdkInited = true
}

function create({
  token,
  target = Target.FULLSCREEN,
  profile_id: profileId
}: {
  token: string
  target?: Target
  profile_id?: string
}) {
  if (!sdkInited) {
    throw createError(
      ErrorName.SDK_NOT_INITED_ERROR,
      'The HPP Distribution Module SDK has to be initialized before calling the create call.'
    )
  }

  if (!avaiableTargets.includes(target)) {
    throw createError(ErrorName.INVALID_PARAMETER_ERROR, 'Invalid target option.')
  }

  const viewId = uuidV4()
  const emitter = new Emitter()
  let url
  let origin
  let eventUrl
  let sessionId

  try {
    const jwtData = jwtDecode(token)

    sessionId = jwtData.session_id
    url = jwtData.iframe_url
    origin = jwtData.iframe_origin
    eventUrl = jwtData.event_url
  } catch (err) {
    throw createError(ErrorName.INVALID_TOKEN_ERROR, 'The provided token is invalid.')
  }

  if (typeof url !== 'string' || typeof origin !== 'string') {
    throw createError(ErrorName.INVALID_TOKEN_ERROR, 'The provided token is invalid.')
  }

  const trackEvent = createTracker({ eventUrl, sessionId })

  const urlWithOptions = url
    .replace('{{config_id}}', profileId || '')
    .replace('{{profile_id}}', profileId || '')
    .replace('{{view_id}}', String(viewId))

  let messageListener: MessageListener
  let instance: {
    show(): void
    close(): Promise<void>
    postMessage(string): void
  }

  function closeAndEmitEvent(name, data) {
    return instance.close().then(() => {
      instance = null
      messageListener.destroy()
      emitter.emit(name, data)
    })
  }

  trackEvent('distribution-module-sdk-create')

  return {
    on: emitter.addListener.bind(emitter),
    off: emitter.removeListener.bind(emitter),
    open(callback: (err?: Error) => void = () => {}) {
      if (instance) {
        return
      }

      let loadTimeout

      function onLoadTimeout() {
        instance.close()
        trackEvent('distribution-module-sdk-load-error')
        callback(
          createError(ErrorName.MODULE_DID_NOT_LOAD, 'The HPP Distribution Module did not load.')
        )
      }

      function onLoad() {
        instance.show()
        trackEvent('distribution-module-sdk-openned')
        clearTimeout(loadTimeout)
        callback()
      }

      function onEnd(status) {
        emitter.emit(Event.END, status)
      }

      function onKeyboardInputResponse(status) {
        emitter.emit(Event.KEYBOARD_INPUT_RESPONSE, status)
      }

      function onClose(state) {
        trackEvent('distribution-module-sdk-close', state)
        closeAndEmitEvent(Event.CLOSE, state)
      }

      function onClientError(error) {
        clearTimeout(loadTimeout)
        trackEvent('distribution-module-sdk-client-error', { error })
        closeAndEmitEvent(Event.CLIENT_ERROR, createError(error.name, error.message))
      }

      trackEvent('distribution-module-sdk-open')

      loadTimeout = setTimeout(onLoadTimeout, 10 * 1000)

      messageListener = new MessageListener(viewId, origin)
      messageListener.addListener('load', onLoad)
      messageListener.addListener('end', onEnd)
      messageListener.addListener('close', onClose)
      messageListener.addListener('client-error', onClientError)
      messageListener.addListener('keyboard-input-response', onKeyboardInputResponse)

      instance = {
        [Target.FULLSCREEN]: createIframe,
        [Target.NEW_WINDOW]: createNewWindow
      }[target](urlWithOptions)
    },
    close() {
      instance.postMessage('request-close')
    },
    keyboardInputRequest(data, id?) {
      instance.postMessage({ type: 'keyboard-input-request', id, data })
    }
  }
}

export default {
  init,
  create
}
