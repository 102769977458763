import frontendEventsLib from '@klarna/frontend-events'
import { isObject } from './utils'

// @ts-ignore
// This constant will be replaced with the build version by wepback, don't change it.
const clientVersion = process.env.NODE_ENV === 'test' ? 'test' : __VERSION__
let tracker

function formatOptions(object: any): any {
  const newObject = {}

  for (let key in object) {
    const value = object[key]

    if (Array.isArray(value) || isObject(value) || value instanceof Date) {
      newObject[key] = JSON.stringify(value)
    } else {
      newObject[key] = value
    }
  }

  return newObject
}

export default function createTracker({
  eventUrl,
  sessionId
}: {
  eventUrl: string
  sessionId: string
}) {
  const config = {
    client: 'hpp',
    clientVersion,
    sessionId: sessionId || '',
    baseUrl: eventUrl
  }

  if (clientVersion !== 'development' && eventUrl) {
    tracker = frontendEventsLib(config)
  } else {
    console.log('[HPP event] Initializing local tracker', config)
  }

  return function trackEvent(name, options?) {
    if (tracker) {
      tracker.event(name, formatOptions(options))
    } else {
      console.log('[HPP event]', name, options)
    }
  }
}
